import { clearGallery } from "../gallery";
import { showContentAfterLogin } from "../utils";

export class TokenManager {
	private static tokenName = "jwtToken";
	public static localTokenValue: string | null = null;

	public static setToken(token: string, expiresInDays: number = 1): void {
		this.localTokenValue = token;
		const expirationDate = new Date();
		expirationDate.setDate(expirationDate.getDate() + expiresInDays);

		document.cookie = `${
			this.tokenName
		}=${token}; expires=${expirationDate.toUTCString()}; path=/; SameSite=Strict`;
        showContentAfterLogin();
	}

	public static getToken(): string | null {
		if (this.localTokenValue) {
			return this.localTokenValue;
		}

		const cookieString = document.cookie;
		const cookies = cookieString.split("; ");

		for (const cookie of cookies) {
			const [name, value] = cookie.split("=");
			if (name === this.tokenName) {
				this.localTokenValue = decodeURIComponent(value);
                showContentAfterLogin();
				return this.localTokenValue;
			}
		}

		return null;
	}

    public static removeToken(): void {
		this.localTokenValue = null;
		document.cookie = `${this.tokenName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Strict`;
        showContentAfterLogin(true);
        clearGallery();
	}
}
