// gallery.ts
import { createSingleImageFragment, triggerOverlayForImage } from "./components/singleImage";
import { GalleryManager } from "./gallery/galleryManager";
import { login } from "./login";
import { triggerToast } from "./toast";
import { TokenManager } from "./token/tokenManager";
import { triggerOverLay } from "./utils";

let perPage = 50;
let pageNumber = 1;
let contentGenerated = false;
let loadingInfiniteScroller = false;
let padinationShown = false;
let loading = false;

let infinite = true;
const optionsPerPage = ["perPage50", "perPage100", "perPage250", "perPage500"];

let closeButton: HTMLElement | null = null;
let currentPageNumber: HTMLElement | null = null;
let currentPageNumberBottom: HTMLElement | null = null;

function searchImages(event: Event) {
	event.preventDefault();
	pageNumber = 1;
	clearGallery();
	fetchAndDraw();
}

function closeOverlay(event: Event) {
	event.preventDefault();
	triggerOverLay("", true);
}

function signOut(event: Event) {
	event.preventDefault();
	TokenManager.removeToken();
}

function onImagesPerPageChange(event: Event): void {
	const select = event.target as HTMLSelectElement;
	const selectedValue = select.value;
	if (optionsPerPage.includes(selectedValue)) {
		let parsedValue = parseInt(selectedValue.replace("perPage", ""));
		if (parsedValue !== perPage) {
			perPage = parsedValue;
			if (!infinite) {
				fetchAndDraw();
			}
		}
	} else {
		perPage = 50;
	}
}

function onPaginationChange(event: Event): void {
	const select = event.target as HTMLSelectElement;
	const selectedValue = select.value;
	let parsedValue: boolean;
	selectedValue.toLocaleUpperCase() === "INFINITE" ? (parsedValue = true) : (parsedValue = false);

	if (parsedValue !== infinite) {
		infinite = parsedValue;
		if (infinite) {
			triggerPadination();
		} else if (contentGenerated) {
			triggerPadination(false);
			updatePage();
		}
	}
}

function updatePage() {
	if (!currentPageNumber) {
		currentPageNumber = document.getElementById("currentPage");
		currentPageNumberBottom = document.getElementById("currentPageBottom");
	}
	currentPageNumber!.innerText = pageNumber.toString();
	currentPageNumberBottom!.innerText = pageNumber.toString();
	fetchAndDraw();
}

function handlePrev() {
	if (!loading) {
		pageNumber--;
		updatePage();
		if (pageNumber > 1) {
			if (pageNumber < GalleryManager.totalPages) {
				updateNextPrev("nextBtn", false);
			}
			updateNextPrev("prevBtn", false);
		} else {
			updateNextPrev("prevBtn");
		}
	} else {
		triggerToast("Loading in the process...", "warning");
	}
}

function handleNext() {
	if (!loading) {
		pageNumber++;
		updateNextPrev("prevBtn", false);
		updatePage();
		if (GalleryManager.totalPages == pageNumber) {
			updateNextPrev("nextBtn");
		}
	}else{
		triggerToast("Loading in the process...", "warning");
	}
}

function updateNextPrev(id: "nextBtn" | "prevBtn", hidden = true) {
	const btnTop = document.getElementById(id) as HTMLButtonElement;
	const btnBottom = document.getElementById(id + "Bottom") as HTMLButtonElement;
	if (btnTop && btnBottom) {
		if (hidden) {
			btnTop.classList.add("hidden");
			btnBottom.classList.add("hidden");
		} else {
			btnTop.classList.remove("hidden");
			btnBottom.classList.remove("hidden");
		}
	}
}

export function triggerLodingHeader(hidden = true) {
	const loadingImagesTitle = document.getElementById("loadingImagesTitle") as HTMLHeadingElement;
	if (loadingImagesTitle && !padinationShown) {
		if (hidden) {
			loadingImagesTitle.classList.add("hidden");
		} else {
			loadingImagesTitle.classList.remove("hidden");
		}
	}
}

function triggerPadination(hidden = true) {
	const padinationContainer = document.getElementById("padinationContainer") as HTMLElement;
	const btnBpadinationContainerBottomottom = document.getElementById("padinationContainerBottom") as HTMLElement;
	if (padinationContainer && btnBpadinationContainerBottomottom) {
		if (hidden) {
			padinationContainer.classList.add("hidden");
			btnBpadinationContainerBottomottom.classList.add("hidden");
			padinationShown = false;
		} else {
			padinationContainer.classList.remove("hidden");
			btnBpadinationContainerBottomottom.classList.remove("hidden");
			padinationShown = true;
		}
	}
}

export function clearGallery(): void {
	const gallery = document.getElementById("results-image-container") as HTMLElement;
	gallery.innerHTML = "";
	contentGenerated = false;
	pageNumber = 1;
	GalleryManager.clear();

	if (!closeButton) {
		closeButton = document.getElementById("clearAll") as HTMLElement;
	}
	closeButton.classList.add("hidden");
}

export async function fetchAndDraw(nextPageIndicator: boolean = false): Promise<void> {
	if (!loading) {
		loading = true;
		const searchInput: string = (document.getElementById("searchInput") as HTMLInputElement).value;

		if (nextPageIndicator) {
			pageNumber++;
		}

		if (searchInput.length > 2) {
			const data = await GalleryManager.loadPage(searchInput, pageNumber, perPage);
			const gallery = document.getElementById("results-image-container") as HTMLElement;

			if (data.length) {
				const imageFragmentsToAppend: DocumentFragment = document.createDocumentFragment();
				data.forEach((item: { url: string; title: string }) => {
					imageFragmentsToAppend.appendChild(createSingleImageFragment(item.url, item.title));
				});

				const closeButton = document.getElementById("clearAll") as HTMLElement;
				closeButton.classList.remove("hidden");

				if (!infinite) {
					gallery.innerHTML = "";
				}
				gallery.append(imageFragmentsToAppend);
				contentGenerated = true;
				loadingInfiniteScroller = false;
			} else {
				triggerToast("Didn't receive any images from Flickr. Try again with a different query.", "warning");
			}
		} else {
			triggerToast("Search query needs to be at least 3 characters long.", "warning");
		}
		loading = false;
		triggerLodingHeader();
		if (!infinite && !padinationShown && contentGenerated) {
			triggerPadination(false);
		}
	} else {
		triggerToast(
			"Loading in the process, your action was not registered. Try again after loading is over",
			"warning"
		);
	}
}

const eventListeners: Array<{ element: HTMLElement | Element; event: string; handler: EventListener }> = [];

function addTrackedEventListener(id: string, event: string, handler: EventListener): void {
	const eventElement = document.getElementById(id) as HTMLElement;
	eventElement.addEventListener(event, handler);
	eventListeners.push({ element: eventElement, event, handler });
}

function removeAllEventListeners(): void {
	eventListeners.forEach(({ element, event, handler }) => {
		element.removeEventListener(event, handler);
	});
	eventListeners.length = 0;
}
function preventDef(event: Event) {
	event.preventDefault();
}

function preventEmptyAnchorClick() {
	const anchorLinks = document.querySelectorAll('a[href="#"]');

	anchorLinks.forEach((anchor: Element) => {
		eventListeners.push({ element: anchor, event: "click", handler: preventDef });
		anchor.addEventListener("click", preventDef);
	});
}

function monitorScroll(threshold: number = 0.85, callback: () => void): void {
	const handleScroll = () => {
		const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
		const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
		const clientHeight = document.documentElement.clientHeight || window.innerHeight;

		if (scrollTop + clientHeight >= scrollHeight * threshold) {
			callback();
		}
	};

	window.addEventListener("scroll", handleScroll);
}

// Assign event handlers here
preventEmptyAnchorClick();
addTrackedEventListener("searchForm", "submit", searchImages);
addTrackedEventListener("loginForm", "submit", login);
addTrackedEventListener("clearAll", "click", clearGallery);
addTrackedEventListener("results-image-container", "click", triggerOverlayForImage);
addTrackedEventListener("closeOverlay", "click", closeOverlay);
addTrackedEventListener("overlay", "click", closeOverlay);
addTrackedEventListener("signOut", "click", signOut);
addTrackedEventListener("imagesPerPgae", "change", onImagesPerPageChange);
addTrackedEventListener("pageInfinite", "change", onPaginationChange);
addTrackedEventListener("nextBtn", "click", handleNext);
addTrackedEventListener("prevBtn", "click", handlePrev);
addTrackedEventListener("nextBtnBottom", "click", handleNext);
addTrackedEventListener("prevBtnBottom", "click", handlePrev);
window.addEventListener("", removeAllEventListeners);
window.addEventListener("beforeunload", removeAllEventListeners);

monitorScroll(0.85, () => {
	if (infinite && !loadingInfiniteScroller) {
		loadingInfiniteScroller = true;
		fetchAndDraw(true);
	}
});
