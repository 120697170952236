// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Roboto-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Roboto-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Roboto-Regular.eot", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
	--primary: #000735;
	--info: #3498db;
	--success: #2ecc71;
	--danger: #e74c3c;
	--warning: #f39c12;
	--dark: #272727;
}

@font-face {
	font-family: "Roboto";
	src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff"),
		url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype"), url(${___CSS_LOADER_URL_REPLACEMENT_3___});
	font-weight: normal;
	font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/css/variables.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,eAAe;CACf,kBAAkB;CAClB,iBAAiB;CACjB,kBAAkB;CAClB,eAAe;AAChB;;AAEA;CACC,qBAAqB;CACrB;qGAC0F;CAC1F,mBAAmB;CACnB,kBAAkB;AACnB","sourcesContent":[":root {\n\t--primary: #000735;\n\t--info: #3498db;\n\t--success: #2ecc71;\n\t--danger: #e74c3c;\n\t--warning: #f39c12;\n\t--dark: #272727;\n}\n\n@font-face {\n\tfont-family: \"Roboto\";\n\tsrc: url(\"../fonts/Roboto-Regular.woff2\") format(\"woff2\"), url(\"../fonts/Roboto-Regular.woff\") format(\"woff\"),\n\t\turl(\"../fonts/Roboto-Regular.ttf\") format(\"truetype\"), url(\"../fonts/Roboto-Regular.eot\");\n\tfont-weight: normal;\n\tfont-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
