import { triggerToast } from "./toast";
import { TokenManager } from "./token/tokenManager";

export async function login(event: Event): Promise<void> {
    event.preventDefault();
    const url = 'https://flickrapi.athenaboard.dk/api/login';
    const headers = {
        'Accept': '*/*',
        'Content-Type': 'application/json',
    };

    const username = (document.getElementById("username") as HTMLInputElement).value;
    const password = (document.getElementById("password") as HTMLInputElement).value;
    const body = JSON.stringify({ username, password });

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: body,
        });

        if (!response.ok) {
            console.error(`HTTP error! Status: ${response.status}`);
            triggerToast("Something went wrong, try again later!", "danger");
        }

        const token: string = ((await response.json()) as {token: string}).token;
        TokenManager.setToken(token);
        triggerToast("Logged in succesfully!", "success");
    } catch (error) {
        console.error(error);
        triggerToast("Failed to login!", "danger");
    }
}