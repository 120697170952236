import './css/main.css';

import './script/images/assets.d.ts';
import './assets/loading.gif';
import './assets/top-arrow.png';
import './assets/favicon.png';
import './assets/logo.png';
import './assets/horizontal.jpg';
import './assets/vertical.jpg';
import { TokenManager } from './script/token/tokenManager';

function showApp() {
    const loadingDiv = document.getElementById('loading');
    const appDiv = document.getElementById('app');

    if (loadingDiv) loadingDiv.classList.add('hidden'); // Hide loading
    if (appDiv) appDiv.classList.remove('hidden'); // Show the app
    TokenManager.getToken();
}

if (document.readyState === 'complete') {
    showApp(); 
} else {
    window.addEventListener('load', () => {
        showApp();
    });
}
