import { ToastType } from "./types";
import { generateUniqueId } from "./utils";

let toastContainer: HTMLElement | null = null;

function getToastContainer(): HTMLElement {
	if (!toastContainer) {
		toastContainer = document.createElement("div");
		toastContainer.id = "toast-container";
		document.body.appendChild(toastContainer);
	}
	return toastContainer;
}

function toastTemplate(): { template: string; id: string } {
	const uniqueId = generateUniqueId();
	const template = `
    <div class="toast" id="${uniqueId}">
        <h3 class="toast-title"></h3>
        <p class="toast-message"></p>
    </div>
    `;
	return { template: template, id: uniqueId };
}

export function triggerToast(message: string, toastType: ToastType, seconds?: number): void {
	const container = getToastContainer();
    const toastData = toastTemplate();

	const template = document.createElement("template");
	template.innerHTML = toastData.template.trim();
	const toast = document.importNode(template.content, true);

	const title = toast.querySelector(".toast-title") as HTMLElement;
	const toastMessage = toast.querySelector(".toast-message") as HTMLElement;

	title.textContent = toastType.toUpperCase();
	title.classList.add("toast-" + toastType);
	toastMessage.textContent = message;

	container.appendChild(toast);
	setTimeout(() => removeToast(toastData.id), seconds ? seconds * 1000 : 3000);
}

function removeToast(toastId: string): void {
	const toast = document.getElementById(toastId);
	if (toast) {
		toast.classList.add("fade-out");
		toast.addEventListener("transitionend", () => {
			toast.remove();
			if (toastContainer && toastContainer.childElementCount === 0) {
				toastContainer.remove();
				toastContainer = null;
			}
		});
	}
}
