import { triggerLodingHeader } from "../gallery";
import { triggerToast } from "../toast";
import { TokenManager } from "../token/tokenManager";
import { Photo, PhotosResponse } from "../types";

export class GalleryManager {
	public static imageUrls: Set<{url: string, title: string}> = new Set();
	public static currentImages: {url: string, title: string}[] = [];
	public static currentPage: number = 1;
	public static imagesPerPage: number = 50;
	public static totalImages: number = 0;
	public static totalPages: number = 0;

	private static async fetchImages(inputValue: string, page: number, perPage: number): Promise<PhotosResponse | null> {
		triggerToast("Loading images", "info", 2);
        triggerLodingHeader(false);
		try {
			const headers = {
				Accept: "*/*",
				"Content-Type": "application/json",
				Authorization: "Bearer " + TokenManager.localTokenValue,
			};

			const response = await fetch(
				`https://flickrapi.athenaboard.dk/api/search?query=${inputValue}&perPage=${perPage}&page=${page}`,
				{ headers: headers }
			);
			if (!response.ok) {
				triggerToast("API is not responding. Try again later!", "danger");
				console.error(`HTTP error! status: ${response.status}`);
			}
			return await response.json();
		} catch (error) {
			triggerToast("Something went wrong with getting data from Flickr.", "danger");
			console.error("Error fetching data:", error);
            return null;
		}
	}

	public static addImages(newImages: {url: string, title: string}[]) {
		newImages.forEach((item) => {
			this.imageUrls.add({url: item.url, title: item.title});
		});
		this.currentImages = Array.from(this.imageUrls);
		this.totalImages = this.currentImages.length;
	}

	public static async loadPage(inputValue: string,page: number, perPage: number): Promise<{url: string, title: string}[]> {
		this.imagesPerPage = perPage;
		this.currentPage = page;

		const startIndex = (this.currentPage - 1) * this.imagesPerPage;
		const endIndex = startIndex + this.imagesPerPage;

		if (startIndex >= this.totalImages) {
			const nextPage = Math.ceil(this.totalImages / this.imagesPerPage) + 1;
			const newImages = await this.fetchImages(inputValue,nextPage, this.imagesPerPage);

            console.log(newImages);

            if (newImages !== null && newImages.photos.photo.length) {
                this.totalPages = newImages.photos.pages;
                const newImagesToStore: {url: string, title: string}[] = [];

                newImages.photos.photo.forEach((item: Photo) => {
                    const url = `https://live.staticflickr.com/${item.server}/${item.id}_${item.secret}_b.jpg`;
                    newImagesToStore.push({url: url, title: item.title});
                });
                
                this.addImages(newImagesToStore);
            } else {
                triggerToast("Didn't receive any images from Flickr. Try again with a different query.", "warning");
                return [];
            }

		}

		return this.currentImages.slice(startIndex, endIndex);
	}

	public static getCurrentPage(): number {
		return this.currentPage;
	}

	public static clear(): void {
		this.imageUrls.clear();
		this.currentImages = [];
		this.currentPage = 1;
		this.totalImages = 0;
        this.totalPages = 0;
	}
}
