import { triggerToast } from "../toast";
import { triggerOverLay } from "../utils";

const singleImage: string = `
<div class="component">
    <img src="./assets/logo.png" alt="Example of the image" class="component-image" />
    <p class="component-description">Description</p>
</div>
`;

export function createSingleImageFragment(src: string, title: string): DocumentFragment {
	const template = document.createElement("template");
	template.innerHTML = singleImage.trim();

	const fragment = document.importNode(template.content, true);
	const img = fragment.querySelector(".component-image") as HTMLImageElement;
	const paragraph = fragment.querySelector(".component-description") as HTMLElement;

	if (img) {
		img.src = src;
		img.alt = title;
	}

	if (paragraph) {
		paragraph.textContent = title;
	}

	return fragment;
}

export function triggerOverlayForImage(event: Event) {
	const target = event.target as HTMLElement;

	if (target.classList.contains("component-image")) {
		const data = target.getAttribute("src");
		if (data) {
			triggerOverLay(data);
		} else {
			triggerToast("Could not enlarge the image at this time", "warning");
		}
	}
}
