let idCounter = 0;
let overlayElement = document.getElementById("overlay") as HTMLElement;
let overlayImage = document.getElementById("overlay-image") as HTMLImageElement;

export function generateUniqueId(prefix: string = "id"): string {
	const timestamp = Date.now().toString(36);
	const randomPart = (Math.random() * 1e16).toString(36);
	idCounter += 1;
	return `${prefix}_${timestamp}_${randomPart}_${idCounter}`;
}

export function triggerOverLay(imgSrc: string, close?: boolean) {
	if (!overlayElement) {
		overlayElement = document.getElementById("overlay") as HTMLElement;
	}
	if (!overlayImage) {
		overlayImage = document.getElementById("overlay-image") as HTMLImageElement;
	}

	if (close === true) {
		overlayElement.classList.add("hidden");
		overlayImage.src = "";
		return;
	}
	overlayImage.src = imgSrc;
	overlayElement.classList.remove("hidden");
}

export function showContentAfterLogin(hide: boolean = false) {
	const mainTitle = document.getElementById("main-title") as HTMLElement;
	const results = document.getElementById("results-container") as HTMLElement;
	const searchFilter = document.getElementById("search-filter") as HTMLElement;
	const signOut = document.getElementById("signOut") as HTMLElement;
	const loginForm = document.getElementById("login") as HTMLElement;

    if(hide) {
        [mainTitle, results, searchFilter, signOut].forEach((item) => {
            item.classList.add("hidden");
        });
        loginForm.classList.remove("hidden");
    }else {
        [mainTitle, results, searchFilter, signOut].forEach((item) => {
            item.classList.remove("hidden");
        });
        loginForm.classList.add("hidden");
    }
}
